import * as React from "react";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import brochurePdf from "../pdf/Brochure_Conworth_En.pdf";

const AboutPage = () => {
  return (
    <Layout>
      <section id="about"></section>
      <div className="top_section">
        <div className="bg about"></div>
        <div className="inner_sub">
          <div className="top_text">About Us</div>
          <p>
            Conworth aims to become a key platform company for digital twins
            through management technology of converged information in
            construction
          </p>
          <div className="link-container">
            <div>
              <div className="youtube-logo">
                <a href="https://www.youtube.com/channel/UCn_ZeOcxdBKqA53uePcM3kQ">
                  <StaticImage
                    style={{
                      maxWidth: "70px",
                    }}
                    src="../images/유튜브_채널_링크_이미지.png"
                    alt="Youtube_channel"
                  />
                </a>
                <StaticImage
                  style={{
                    maxWidth: "80px",
                  }}
                  src="../images/FindUs.png"
                  alt="find_us"
                />
              </div>
              <a href="https://www.youtube.com/channel/UCn_ZeOcxdBKqA53uePcM3kQ?sub_confirmation=1">
                <div className="youtube-subscribe-logo">
                  <StaticImage
                    style={{
                      maxWidth: "80px",
                    }}
                    src="../images/유튜브_구독_링크_이미지.png"
                    alt="Youtube_subscribe"
                  />
                </div>
              </a>
            </div>
            <div>
              <a href={brochurePdf} target="_blank">
                <div className="download-brochure-button">
                  Download Brochure
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <section id="about"></section>
        <div className="sub_section">
          <h2>Why Conworth?</h2>
          <ul className="about_info">
            <li>
              <span className="img">
                <StaticImage src="../images/img_about01.png" alt="" />
              </span>
              <strong className="tit">Overwhelming Technology </strong>
              <p>
                Proprietary edge-cloud and digital twining technology which
                outperforms other solutions
              </p>
            </li>
            <li>
              <span className="img">
                <StaticImage src="../images/img_about02.png" alt="" />
              </span>
              <strong className="tit">Client Focus</strong>
              <p>
                Client-specific customized solutions which present the best
                value from the clients’ perspective
              </p>
            </li>
            <li>
              <span className="img">
                <StaticImage src="../images/img_about03.png" alt="" />
              </span>
              <strong className="tit">Field-Oriented</strong>
              <p>
                Considering quality, safety, and productivity improvement of
                clients’ sites as the top priority
              </p>
            </li>
            <li>
              <span className="img">
                <StaticImage src="../images/img_about04.png" alt="" />
              </span>
              <strong className="tit">Data as Asset</strong>
              <p>
                Converting data into valuable asset for the future of
                construction industry throughout digital transformation
              </p>
            </li>
          </ul>
        </div>
        <div className="sub_section bg_gray">
          <h2>Partners</h2>
          <ul className="biz_list">
            <li className="img1">
              <StaticImage
                style={{
                  maxWidth: "300px",
                  // maxHeight: "100px",
                  // width: "100%",
                }}
                src="../images/logo_yonsei.png"
                alt="연세대학교"
              />
            </li>
            <li className="img2">
              <StaticImage
                style={{
                  maxWidth: "300px",
                  // maxHeight: "100px",
                  // width: "100%",
                }}
                src="../images/logo_boston.png"
                alt="Boston Dynamics"
              />
            </li>
            <li className="img3">
              <StaticImage
                style={{
                  maxWidth: "300px",
                  // maxHeight: "100px",
                  // width: "100%",
                }}
                src="../images/logo_platinum.png"
                alt="플래티넘기술투자"
              />
            </li>
            <li className="img4">
              <StaticImage
                style={{
                  maxWidth: "250px",
                  // maxHeight: "100px",
                  // width: "100%",
                }}
                src="../images/logo_fargo.png"
                alt="FARO"
              />
            </li>
            <li className="img5">
              <StaticImage src="../images/logo_wipco.png" alt="wipco" />
            </li>
            <li className="img6">
              <StaticImage src="../images/logo_uok.png" alt="UOK" />
            </li>
            <li className="img7">
              <StaticImage src="../images/Clients_KODIT_1.png" alt="KODIT" />
            </li>
            <li className="img8">
              <StaticImage
                // style={{
                //   marginLeft: "20px",
                // }}
                src="../images/Partners_Kwater_resize.png"
                alt="Partners_Kwater"
              />
            </li>
            <li className="img9">
              <StaticImage src="../images/Partners_LH.png" alt="Partners_LH" />
            </li>
            <li className="img10">
              <StaticImage
                src="../images/Partners_도로공사.png"
                alt="Partners_도로공사"
              />
            </li>
          </ul>
        </div>
        <div className="sub_section">
          <h2>Clients</h2>
          <ul className="biz_list">
            <li className="img01">
              <StaticImage
                style={{
                  maxWidth: "270px",
                  // maxHeight: "100px",
                  // width: "100%",
                }}
                src="../images/logo_hy.png"
                alt="현대엔지니어링"
              />
            </li>
            <li className="img02">
              <StaticImage
                style={{
                  maxWidth: "250px",
                  // maxHeight: "100px",
                  // width: "100%",
                }}
                src="../images/logo_hy_eng.png"
                alt="현대건설"
              />
            </li>
            <li className="img03">
              <StaticImage
                style={{
                  maxWidth: "300px",
                  // maxHeight: "100px",
                  // width: "100%",
                }}
                src="../images/logo_ss_cnt.png"
                alt="삼성 C$amp;T"
              />
            </li>
            <li className="img04">
              <StaticImage
                src="../images/logo_lotte_enc.png"
                alt="롯데 E$amp;C"
              />
            </li>
            <li className="img05">
              <StaticImage
                style={{
                  maxWidth: "300px",
                  // maxHeight: "100px",
                  // width: "80%",
                }}
                src="../images/logo_posco.png"
                alt="포스코 E$amp;C"
              />
            </li>
            <li className="img06">
              <StaticImage
                style={{
                  maxWidth: "300px",
                  // maxHeight: "100px",
                  // width: "100%",
                }}
                src="../images/logo_kolon.png"
                alt="코오롱 글로벌"
              />
            </li>
            <li className="img07">
              <StaticImage
                style={{
                  maxWidth: "300px",
                  // maxHeight: "100px",
                  // width: "100%",
                }}
                src="../images/logo_ministry.png"
                alt="국방부"
              />
            </li>
            <li className="img08">
              <StaticImage
                style={{
                  maxWidth: "300px",
                  // maxHeight: "100px",
                  // width: "100%",
                }}
                src="../images/logo_incheon.png"
                alt="인천시"
              />
            </li>
            <li className="img09">
              <StaticImage src="../images/logo_uljin.png" alt="울진군" />
            </li>
            <li className="img10">
              <StaticImage
                style={{
                  maxWidth: "200px",
                  // maxHeight: "100px",
                  // width: "100%",
                  marginLeft: "20px",
                }}
                src="../images/7-1.png"
                alt="LG화학"
              />
            </li>
            <li className="img11">
              <StaticImage
                style={{
                  maxWidth: "200px",
                  // maxHeight: "100px",
                  // width: "70%",
                  // marginLeft: "20px",
                }}
                src="../images/Clients_AutoEver.png"
                alt="Clients_AutoEver"
              />
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default AboutPage;
